@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');

.main{
    background-color: black;
}

/* .main-image{
    float: right;
    margin: 50px;
    height: 500px;
    width: 500px;
} */

/*------------ Navbar Css ------------*/

.navbar {
  background: black;
  height: 60px;
  transition: transform .05s;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding-top: 10px; */
  font-size: 1.2rem;
  border-style: solid;
  border-bottom-width: 1px;
  border-bottom-color:  rgb(255, 255, 255);

}
  
.navbar-logo {
  color: #fff;
  justify-self:center;
  /* margin-left: 20px; */
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
}

.nav-ul {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 40px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: start;
  margin-right: 2rem;
}

.nav-li {
  /* padding-top: 5px; */
  display: flex;
  align-items: center;
  height: 80px;
}

.nav-links {
  color: white;
  transition: transform .2s;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.navbar:hover {
  
  background:rgb(255, 255, 255);
  border-width: 3px;

  border-color:  rgb(255, 255, 255);
  
}
.navbar:hover .nav-links{
color: black;  
}

.nav-links:hover {
  /* font-weight: bold; */
  /* filter: drop-shadow(0 0 0.25rem #1d0a30); */
  transform: scale(1.5); 
}

.fa-bars {
  color: rgb(243, 243, 243);
}

/*------------ Main Page ------------*/
.horizontal{
  display: flex;
  flex-direction: row;
  justify-content: center; 
  align-items: center;
  padding-top: 3%;
}
.main-text{
  height: 500px;
  width: 700px;
  min-width: 600px;
  text-align: center;
  color: white;
  padding-top: 90px;
  
  /* border-style: solid;
  border-radius: 25px;
  border-width: 3px;
  border-color: white; */
  margin: 7% 6% 0 0;
}
.tempDiv2{
  height: 500px;
  width: 500px;
  min-width: 600px;
  padding-bottom: 100px;
}

.main-project{
  display: flex;
  flex-direction: column;
  font-size: 50px;
  justify-content: center; 
  align-items: center;
  color: white;
  padding-top: 4%;
}

.main-title{

}
.main-image-holder{
  display: flex;
  flex-direction: row;
  margin-top: 3%;
}

.main-progress-i{
  margin: 0 40px;
  height: 400px;
  width: 450px;
  border-style: solid;
  border-radius: 25px;
  border-width: 3px;
  border-color: white;
}

.main-progress-l{
  margin: 0 40px;
  height: 400px;
  width: 450px;
  padding: 10% 0 0 0;
  overflow: hidden;
/* padding-top: 10%; */
  border-style: solid;
  border-radius: 25px;
  border-width: 3px;
  border-color: white;
}

.loading-progress{
  margin:0 auto;
}

.main-image{
  min-width: 100%;
  min-height: 100%;
  width: 200px;
  height: 600px;
}

.main-hello{
  font-size: 100px;
  font-weight:lighter;
  letter-spacing: 3px;
  margin-bottom: 20px;
}
.main-info{
  filter: drop-shadow(0 0 0.55rem #1d0a30);
  border-radius: 25px;
  /* background: linear-gradient(180deg, #311f46 0%, #442863 70%); */
  font-size: 25px;
}

.main-p-link{
  color: white;
  margin-top: 2%;
}



/*------------ About Page ------------*/

.about-horizontal{
  display: flex;
  flex-direction: row;  
  justify-content: center; 
  align-items: center;
  padding: 7% 0;
  color: white;
}

.about-text{
  margin: 0 auto;
  height: 600px;
  width:  600px;
  max-width: 800px;
  max-height: 800px;
  font-size: 25px;
  line-height: 150%;
  text-align: left;
  padding: 80px 0 0 0;

}

.about-div{
  filter: drop-shadow(0 0 0.25rem #1d0a30);
  margin: 0 auto;
  float: left;
  height: 600px;
  width:  600px;
  max-width: 800px;
  max-height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden
}

.about-image{
  min-width: 100%;
  min-height: 100%;
}

/*------------ Contacts Page ------------*/

.contacts-horizontal{
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 100px;
  justify-content: center; 
  align-items: center;
  min-height: 90vh;
  color: white;
}

/* .contacts-div{

} */

.contacts-img{
  height: 230px;
  width:  230px;
}

.contacts-div:hover {
  animation: shake 2s;
  animation-iteration-count: infinite;
}

/* @keyframes shake {
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
} */

@keyframes shake {

  0% { transform: translate(0,0px)  rotate(0deg); }
  20% { transform: translate(0,-30px)  rotate(5deg); }
  50% { transform: translate(0,20px)  rotate(-5deg); }

}


/*------------ Testing ------------*/

.to-contacts-page{
  background-image: url("https://imgur.com/3yOY0WY.png");
}

.to-contacts{
  height: 600px;
  display:block;
  justify-content: center; 
  margin: 40vh auto;
  align-items: center;
  color: white;
  text-decoration: none;
  font-size: 70px;
  text-align: center;
  padding: 0 14%;
  transition: transform .05s;
}

.contacts-word{
  
  margin: 50vh auto;
  color: white;
  text-decoration: none;
}

.to-contacts:hover {
  color: transparent;
  transform: translate(50px,80px)  scale(1.3);
  
}

.to-contacts:hover .contacts-word {
  /* text-decoration: underline; */
  color:#6ae25f;
}​

.circle {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  left: 50%;
  margin: -50px 0 0 -50px;
  background: white;
  border-radius: 80%;
  backface-visibility: hidden;
}

/* .disabled{} */

.hide {
  display: none;
}
    
.disabled:hover + .hide {
  display: block;
  color: rgb(255, 255, 255);
  text-align: center;
  margin-top: 5%;
}


.lds-ellipsis {
  display: flex;
  position: relative;
  margin: auto;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
