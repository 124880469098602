@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Fredoka One', cursive;
    /* display: flex; */
    /* height: 90vh; */
    /* align-items: center; */
    /* justify-content: center; */
    /* font-size: 3rem; */
  }